import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Cursul acesta, pe care-l sugerăm fiecărui pastor sau slujitor, s-a născut din cartea cu același nume scrisă de pastorul Bill Hull din experiența înnoitoare personală, ce a avut-o în lucrare.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Dacă bisericile au pierdut mandatul primit de la Domnul este, în cele mai multe cazuri, din cauza pastorilor; Evanghelizarea lumii este imposibilă fără facerea de ucenici, iar din punct de vedere biblic, după modelul lui Isus, responsabilitatea de formare de slujitori aparține pastorilor. Fiecare dintre noi putem fi un pastor activ care să zidească biserica orientată spre ucenicizare. Accentul nu va fi pe programe, pe dimensiunea sanctuarului și nici pe numărul mare de participanți la serviciile publice, ci pe calitatea vieții celor ce au devenit creștini.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Pastorul Ucenicizator este o carte care ne ajută să înțelegem sarcina liderului de a delega responsabilitate și autoritate unor oameni echipați pentru slujire, de a lucra ca antrenor de echipă care formează, împuternicește și mentorează pe alții în lucrare.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Păstorul ucencizator își petrece cea mai mare parte a timpului cu cei doritori să fie antrenați pentru a se reproduce din punct de vedere spiritual, cu cei ce împărtășesc visul Împărăției, care s-au dedicat crucii și care văd în multiplicare metodologia poruncită de Hristos pentru a câștiga lumea pentru El. Să nu uităm că ucenicizarea nu este un scop în sine. Scopul nostru este acela de a popula cerul și de a salva cât mai mulți oameni, dându-le mesajul care schimbă vieți, mesajul mântuirii lui Hristos!”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    